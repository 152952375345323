import React, { FC, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Laurel from '../../desktop/desktop_laurel';
import LaurelWinner from '../../desktop/desktop_laurel_winner';
import Button from '../../button/button_browser';
import { award, credits } from '../../../util/models';
import { deviceSize } from '../../../util/styledComponents';
import { breakpoint } from '../../../util/constant';
import { SiteContext } from '../../../context';

const Base = styled.div`
  width: 100%;
  ${deviceSize.lessThan(breakpoint.mobile)`
    display: none;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding-bottom: 10px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
`;

const LaurelContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const Gap = styled.div`
  width: 5px;
  height: 5px;
`;

const StaticButton = styled.div<{ winner: boolean }>`
  width: 150px;
  color: ${p =>
    p.winner ? p.theme.colour.secondaryDark : p.theme.colour.primary};
  border: 1px solid
    ${p => (p.winner ? p.theme.colour.secondaryDark : p.theme.colour.primary)};
  border-radius: 3px;
  padding: 3px 10px 3px 10px;
  user-select: none;
  cursor: pointer;
  text-align: center;
  :hover {
    background-color: ${p => p.theme.colour.grey1};
  }
`;

const ActiveButton = styled.div<{ winner: boolean }>`
  width: 150px;
  color: ${p => p.theme.colour.white};
  background-color: ${p =>
    p.winner ? p.theme.colour.secondaryDark : p.theme.colour.primary};
  border: 1px solid
    ${p => (p.winner ? p.theme.colour.secondaryDark : p.theme.colour.primary)};
  border-radius: 3px;
  padding: 3px 10px 3px 10px;
  user-select: none;
  cursor: pointer;
  text-align: center;
  :hover {
    background-color: ${p =>
      p.winner ? p.theme.colour.secondaryDark : p.theme.colour.secondary};
    border: 1px solid ${p => p.theme.colour.secondary};
    filter: ${p => `hue-rotate(${p.winner ? '-10deg' : 0})`};
  }
`;

const OverallButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
`;

type Props = {
  credits: credits;
};

const Desktop_Awards: FC<Props> = ({ credits }) => {
  const { scrollHeight } = useContext(SiteContext);
  const [filteredAwards, setFilteredAwards] = useState(credits.credits);
  const [visibleAwards, setVisibleAwards] = useState(credits.credits);
  const [visible, setVisible] = useState(10);
  const [winners, setWinners] = useState(false);
  const [nominated, setNominated] = useState(false);
  const [officialSel, setOfficialSel] = useState(false);

  useEffect(() => {
    setVisibleAwards(filteredAwards.slice(0, visible));
  }, [filteredAwards, visible]);

  const setAwards = () => {
    if (!winners && !nominated && !officialSel) {
      setFilteredAwards(credits.credits);
    } else {
      let testArray: string[] = [];
      if (winners) {
        testArray.push('winner');
      }
      if (nominated) {
        testArray.push('nominated');
      }
      if (officialSel) {
        testArray.push('official selection');
      }
      let newAwards: award[] = [];
      credits.credits.filter(x => {
        let index = testArray.findIndex(a => a === x.type);
        if (index !== -1) {
          newAwards.push(x);
        }
      });
      setFilteredAwards(newAwards);
    }
  };

  useEffect(() => {
    setFilteredAwards(credits.credits);
  }, [credits.credits]);

  useEffect(() => {
    setAwards();
  }, [winners, nominated, officialSel]);

  const AwardClick = (url: string | undefined | null) => {
    if (url && url !== '') {
      window.open(url, '_blank');
    }
  };

  const LoadMore = () => {
    let amount = visible + 10;
    if (amount > filteredAwards.length) {
      setVisible(filteredAwards.length);
    } else {
      setVisible(visible + 10);
    }
  };

  return (
    <Base>
      <OverallButtons>
        <ButtonContainer>
          {winners ? (
            <ActiveButton onClick={() => setWinners(false)} winner={true}>
              {'Winners'}
            </ActiveButton>
          ) : (
            <StaticButton onClick={() => setWinners(true)} winner={true}>
              {'Winners'}
            </StaticButton>
          )}
          <Gap />
          {nominated ? (
            <ActiveButton onClick={() => setNominated(false)} winner={false}>
              {'Nominated'}
            </ActiveButton>
          ) : (
            <StaticButton onClick={() => setNominated(true)} winner={false}>
              {'Nominated'}
            </StaticButton>
          )}
          <Gap />
          {officialSel ? (
            <ActiveButton onClick={() => setOfficialSel(false)} winner={false}>
              {'Official Selection'}
            </ActiveButton>
          ) : (
            <StaticButton onClick={() => setOfficialSel(true)} winner={false}>
              {'Official Selection'}
            </StaticButton>
          )}
        </ButtonContainer>
      </OverallButtons>
      <Container>
        {visibleAwards.map((award, index) => (
          <LaurelContainer key={`${award}${index}`}>
            <React.Fragment>
              {award.type === 'winner' ? (
                <LaurelWinner
                  award={award}
                  onClick={() => AwardClick(award.link)}
                  hasLink={award.link !== ''}
                  scrollHeight={scrollHeight}
                />
              ) : (
                <Laurel
                  award={award}
                  onClick={() => AwardClick(award.link)}
                  hasLink={award.link !== ''}
                  scrollHeight={scrollHeight}
                />
              )}
            </React.Fragment>
          </LaurelContainer>
        ))}
      </Container>
      <LoadMoreContainer>
        {visible < filteredAwards.length && (
          <Button onClick={LoadMore} width={'100px'}>
            Load More
          </Button>
        )}
      </LoadMoreContainer>
    </Base>
  );
};

export default Desktop_Awards;
