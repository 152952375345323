import React, { FC } from 'react';
import styled from 'styled-components';

const Circle = styled.circle`
  fill: transparent;
`;

const Line = styled.line<{ colour: string }>`
  fill: none;
  stroke: ${p => (p.colour === 'white' ? 'white' : p.theme.colour.grey5)};
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-linecap: round;
`;

const SVG = styled.svg`
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  :hover ${Circle} {
    fill: ${p => p.theme.colour.primary};
  }
  :active ${Circle} {
    fill: ${p => p.theme.colour.secondary};
  }
`;

type Props = {
  onClick(): void;
  type: 'cross';
  style?: 'white' | 'grey';
};

const Icon: FC<Props> = ({ onClick, style = 'white' }) => {
  return (
    <SVG viewBox='0 0 50 50' onClick={onClick}>
      <Circle cx='25' cy='25' r='25' />
      <Line x1='13' y1='13' x2='37' y2='37' colour={style} />
      <Line x1='37' y1='13' x2='13' y2='37' colour={style} />
    </SVG>
  );
};

export default Icon;
