import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Container = styled.div``;

const ImageWide = styled(GatsbyImage)`
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: 700px) {
    display: none;
  }
  margin: 0 auto;
`;

const ImageThin = styled(GatsbyImage)`
  width: 100%;
  max-width: 1000px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  @media only screen and (min-width: 700px) {
    display: none;
  }
  margin: 0 auto;
`;

const TextLarge = styled.p`
  font-size: ${p => p.theme.fontSize.largeHeading};
  color: ${p => p.theme.colour.primary};
  padding: 0;
  margin: 0;
  margin-top: 50px;
`;

export const Clients: React.FC<{
  thinImage: any;
  wideImage: any;
}> = ({ thinImage, wideImage }) => {
  return (
    <Container>
      <TextLarge>Who We Work With</TextLarge>
      <ImageWide image={getImage(wideImage!)!} alt='' />
      <ImageThin image={getImage(thinImage!)!} alt='' />
    </Container>
  );
};
