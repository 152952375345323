import React, { FC, useEffect, useRef, useContext, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { person } from '../../util/models';
import { getObjectCenterPos } from '../../util';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Clip = styled.div`
  width: 330px;
  height: 450px;
  z-index: 3;
  position: absolute;
  top: 0;
  clip: rect(0px, 450px, 0px, 0px);
  user-select: none;
  overflow: hidden;
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
  opacity: 1;
  user-select: none;
  pointer-events: none;
`;

const Container = styled.div`
  width: 330px;
  position: relative;
`;

const HoverImage = styled(GatsbyImage)`
  user-select: none;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
`;

const ImageContainer = styled.div`
  width: 330px;
  height: 450px;
  position: relative;
  overflow: hidden;
`;

const Divider = styled.div`
  height: 10px;
`;

const Description = styled.div`
  width: 100%;
  font-size: ${p => p.theme.fontSize.body};
  color: ${p => p.theme.colour.grey5};
  pointer-events: none;
  user-select: none;
  text-align: left;
`;

const NameText = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${p => p.theme.fontSize.smallHeading};
  color: ${p => p.theme.colour.primary};
  text-align: left;
  user-select: none;
`;

const BodyText = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${p => p.theme.fontSize.body};
  color: ${p => p.theme.colour.grey5};
  text-align: left;
  user-select: none;
`;

const TextContainer = styled.div`
  opacity: 0;
`;

const OverlayOne = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  user-select: none;
  background-color: black;
  transform: translateY(0px);
  background-color: ${p => p.theme.colour.primary};
`;

const OverlayTwo = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  user-select: none;
  background-color: black;
  transform: translateY(0px);
  background-color: ${p => p.theme.colour.secondary};
`;

const OverlayThree = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  user-select: none;
  background-color: black;
  transform: translateY(0px);
  background-color: ${p => p.theme.colour.accent};
`;

const OverlayFour = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  user-select: none;
  background-color: black;
  transform: translateY(0px);
  background-color: ${p => p.theme.colour.secondaryDark};
`;

type Props = {
  person: person;
  scrollHeight: number;
};

const Desktop_Person: FC<Props> = ({ person, scrollHeight }) => {
  const containerRef = useRef(null);
  const photoRef = useRef(null);
  const textRef = useRef(null);
  const overlay = useRef(null);

  const [animated, setAnimated] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 4)
  );

  const animation = () => {
    gsap.to(photoRef.current!, 1, {
      css: { clip: 'rect(0px, 450px, 450px, 0px)' },
      ease: 'power3.inOut'
    });
    setTimeout(() => {
      gsap.to(overlay.current!, 1, {
        y: 450,
        ease: 'power3.inOut'
      });
    }, 100);
    setTimeout(() => {
      gsap.to(textRef.current!, 1, {
        opacity: 1,
        ease: 'power3.inOut'
      });
    }, 300);
  };

  useEffect(() => {
    if (!animated) {
      const pos = getObjectCenterPos(containerRef.current!);
      if (pos <= 0) {
        setAnimated(true);
        animation();
      }
    }
  }, [scrollHeight]);

  return (
    <Container
      ref={containerRef}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <ImageContainer>
        <Clip ref={photoRef}>
          <ImageContainer>
            <div style={{ opacity: hovering ? 0 : 1 }}>
              <Image image={getImage(person.images[0] as any)!} alt='' />
            </div>
            <HoverImage image={getImage(person.images[1] as any)!} alt='' />
          </ImageContainer>
          {randomNumber === 0 && <OverlayOne ref={overlay} />}
          {randomNumber === 1 && <OverlayTwo ref={overlay} />}
          {randomNumber === 2 && <OverlayThree ref={overlay} />}
          {randomNumber === 3 && <OverlayFour ref={overlay} />}
        </Clip>
      </ImageContainer>
      <TextContainer ref={textRef}>
        <Divider />
        <NameText>{person.name}</NameText>
        <BodyText>{person.title}</BodyText>
        <Divider />
        <Description>{renderRichText(person.description)}</Description>
      </TextContainer>
    </Container>
  );
};

export default Desktop_Person;
