import React, { FC, useRef, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { getObjectCenterPos } from '../../util';
import gsap from 'gsap';
import { SiteContext } from '../../context';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Container = styled.div<{ verticlePadding: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    padding: ${p =>
      p.verticlePadding ? '60px 20px 60px 20px' : '0px 20px 0px 20px'};
    box-sizing: border-box;
    max-width: 700px;
  }
`;

const SecondContainer = styled.div`
  pointer-events: none;
`;

const Divider = styled.div`
  height: 10px;
`;

const SmallDivider = styled.div`
  height: 5px;
`;

const TextContainer = styled.div<{ allWhite: boolean }>`
  top: 0;
  width: 100%;
  box-sizing: border-box;
  grid-area: text;
  > p {
    font-size: 1em;
    color: ${p => (p.allWhite ? 'white' : p.theme.colour.grey5)};
    font-weight: 100;
    max-width: 600px;
    text-align: center;
    padding: 0;
    margin: 0;
    pointer-events: none;
    user-select: none;
  }
`;

const TextBase = styled.p<{ allWhite: boolean }>``;

const TextLarge = styled(TextBase)`
  font-size: ${p => p.theme.fontSize.largeHeading};
  color: ${p => (p.allWhite ? 'white' : p.theme.colour.primary)};
  padding: 0;
  margin: 0;
  opacity: 0;
`;

const TextSmall = styled(TextBase)`
  font-size: ${p => p.theme.fontSize.smallHeading};
  color: ${p => (p.allWhite ? 'white' : p.theme.colour.primary)};
  padding: 0;
  margin: 0;
`;

const TextBody = styled(TextBase)`
  font-size: ${p => p.theme.fontSize.body};
  color: ${p => (p.allWhite ? 'white' : p.theme.colour.grey5)};
`;

const BodyTextContainer = styled.div`
  opacity: 0;
`;

type Props = {
  title: string;
  description: any;
  byline?: string;
  allWhite?: boolean;
  markdown?: boolean;
  verticlePadding?: boolean;
};

const Heading: FC<Props> = ({
  title,
  description,
  byline = false,
  allWhite = false,
  markdown,
  verticlePadding = true
}) => {
  const containerRef = useRef(null);
  const headingRef = useRef(null);
  const textRef = useRef(null);
  const [animated, setAnimated] = useState(false);
  const { scrollHeight } = useContext(SiteContext);

  const animation = () => {
    gsap.to(headingRef.current!, 1, {
      opacity: 1,
      ease: 'power3.inOut'
    });
    setTimeout(() => {
      gsap.to(textRef.current!, 1, {
        opacity: 1,
        ease: 'power3.inOut'
      });
    }, 300);
  };

  useEffect(() => {
    if (!animated) {
      const pos = getObjectCenterPos(containerRef.current!);
      if (pos <= 0) {
        setAnimated(true);
        animation();
      }
    }
  }, [scrollHeight]);
  return (
    <Container ref={containerRef} verticlePadding={verticlePadding}>
      <SecondContainer>
        <TextLarge allWhite={allWhite} ref={headingRef}>
          {title}
        </TextLarge>
        {byline && (
          <React.Fragment>
            <SmallDivider />
            <TextSmall allWhite={allWhite}>{byline}</TextSmall>
          </React.Fragment>
        )}
        <Divider />
        <BodyTextContainer ref={textRef}>
          {markdown ? (
            <TextContainer allWhite={allWhite}>
              {renderRichText(description)}
            </TextContainer>
          ) : (
            <TextBody allWhite={allWhite}>{description}</TextBody>
          )}
        </BodyTextContainer>
      </SecondContainer>
    </Container>
  );
};

export default Heading;
