import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { person } from '../../util/models';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = styled(GatsbyImage)<{ touching: boolean }>`
  width: 100%;
  height: 450px;
  z-index: 3;
  position: relative;
  opacity: ${p => (p.touching ? 0 : 1)};
  user-select: none;
  pointer-events: none;
`;

const MobileContainer = styled.div`
  width: 330px;
  position: relative;
`;

const HoverImage = styled(GatsbyImage)`
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 450px;
  top: 0;
`;

const ImageContainer = styled.div`
  width: 330px;
  height: 450px;
  position: relative;
  pointer-events: none;
`;

const Divider = styled.div`
  height: 10px;
`;

const Description = styled.div`
  width: 100%;
  font-size: ${p => p.theme.fontSize.body};
  color: ${p => p.theme.colour.grey5};
  pointer-events: none;
  user-select: none;
  text-align: left;
`;

const NameText = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${p => p.theme.fontSize.smallHeading};
  color: ${p => p.theme.colour.primary};
  text-align: left;
  user-select: none;
`;

const BodyText = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${p => p.theme.fontSize.body};
  color: ${p => p.theme.colour.grey5};
  text-align: left;
  user-select: none;
`;

type Props = {
  person: person;
};

const Mobile_Person: FC<Props> = ({ person }) => {
  const [touching, setTouching] = useState(false);

  return (
    <MobileContainer
      onTouchStart={() => setTouching(true)}
      onTouchEnd={() => setTouching(false)}
      onTouchCancel={() => setTouching(false)}
    >
      <ImageContainer>
        <Image
          image={getImage(person.images[0] as any)!}
          alt=''
          touching={touching}
        />
        <HoverImage
          image={getImage(person.images[1] as any)!}
          alt=''
          style={{ position: 'absolute' }}
        />
      </ImageContainer>
      <React.Fragment>
        <Divider />
        <NameText>{person.name}</NameText>
        <BodyText>{person.title}</BodyText>
        <Divider />
        <Description>{renderRichText(person.description)}</Description>
      </React.Fragment>
    </MobileContainer>
  );
};

export default Mobile_Person;
