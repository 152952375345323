import React, { FC, useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { INewsNode } from '../../util/models';
import Button from '../button/button_browser';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 50px 20px;
`;

const Stories = styled.div`
  max-width: 1100px;
  width: 100%;
`;

const TextLarge = styled.p`
  font-size: ${p => p.theme.fontSize.largeHeading};
  color: ${p => p.theme.colour.primary};
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
`;

const Post = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0;
  }
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
`;

const Image = styled(GatsbyImage)`
  min-width: 300px;
  width: 300px;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Text = styled.div`
  text-align: left;
  text-overflow: ellipsis;
  color: ${p => p.theme.colour.grey5};
  padding: 30px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    width: calc(100vw - 50px);
  }
`;

const Body = styled.div`
  color: white;
  margin-top: 20px;
  color: ${p => p.theme.colour.dark};
  a {
    color: ${p => p.theme.colour.primary};
  }
`;

const Title = styled.p`
  font-size: 1.5rem;
  margin: 0;
  color: ${p => p.theme.colour.primary};
  position: relative;
  display: inline-block;
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

const NewsSection: FC<{ news: INewsNode[] }> = ({ news }) => {
  const [newsCount, setNewsCount] = useState(2);

  const LoadMore = () => {
    setNewsCount(newsCount + 1);
  };

  return (
    <Container>
      <TextLarge>News</TextLarge>
      <Stories>
        {news.map((post, index) => {
          if (index >= newsCount) {
            return null;
          }
          return (
            <Post key={`Post${index}`}>
              <Image image={getImage(post.image as any)!} alt='' />
              <Text>
                <div style={{ position: 'relative', display: 'inline' }}>
                  <Title>{post.title}</Title>
                </div>
                <Body>{renderRichText(post.content)}</Body>
              </Text>
            </Post>
          );
        })}
      </Stories>
      {news.length > newsCount && (
        <LoadMoreContainer>
          <Button onClick={LoadMore} width={'100px'}>
            Load More
          </Button>
        </LoadMoreContainer>
      )}
    </Container>
  );
};

export default NewsSection;
