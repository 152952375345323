import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { getObjectCenterPos } from '../../util';
import { job, jobLimited } from '../../util/models';
import { SiteContext } from '../../context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Overlay = styled.div<{ random: number }>`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background-image: ${p =>
    p.random === 0
      ? `linear-gradient(
    -45deg,
    ${p.theme.colour.secondary},
    ${p.theme.colour.primary}
  )`
      : p.random === 1
      ? `linear-gradient(
    -45deg,
    ${p.theme.colour.secondaryDark},
    ${p.theme.colour.accent}
  )`
      : p.random === 2
      ? `linear-gradient(
    -45deg,
    ${p.theme.colour.primary},
    ${p.theme.colour.primaryDark}
  )`
      : `linear-gradient(
    -45deg,
    ${p.theme.colour.secondaryDark},
    ${p.theme.colour.secondary}
  )`};
  opacity: 0;
  transition: opacity 0.75s ease;
  box-sizing: border-box;
  pointer-events: none;
`;

const Padding = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  box-sizing: border-box;
  transition: opacity 0.75s ease;
  opacity: 0;
`;

const Border = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid ${p => p.theme.colour.secondaryLight};
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  box-sizing: border-box;
  padding: 15px;
`;

const TextBlock = styled.div`
  box-sizing: border-box;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  pointer-events: none;
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 0.75s ease, filter 0.75s ease;
  transform: scale(1.1);
  pointer-events: none;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  :hover ${Image} {
    transform: scale(1.2);
  }
  :hover ${Overlay} {
    opacity: 0.9;
  }
  :hover ${Padding} {
    opacity: 1;
  }
  box-sizing: border-box;
`;

const TagBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tag = styled.div`
  padding: 7px;
  border-radius: 5px;
  background-color: ${p => p.theme.colour.secondary};
  color: white;
  font-size: ${p => p.theme.fontSize.body};
  box-sizing: border-box;
  display: inline;
`;

const SmallDivider = styled.div`
  height: 5px;
`;

const Divider = styled.div`
  height: 10px;
`;

const TextSmall = styled.p`
  font-size: ${p => p.theme.fontSize.smallHeading};
  color: white;
  padding: 0;
  margin: 0;
  line-height: 25px;
`;

const TextBody = styled.p`
  font-size: ${p => p.theme.fontSize.body};
  color: white;
  padding: 0;
  margin: 0;
`;

const AnimatedContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  clip-path: inset(0 0 100% 0);
`;

const AnimatedOne = styled.div`
  background-color: ${p => p.theme.colour.primary};
  transform: scaleY(1);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-origin: bottom;
`;

const AnimatedTwo = styled.div`
  background-color: ${p => p.theme.colour.secondary};
  transform: scaleY(1);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-origin: bottom;
`;

const AnimatedThree = styled.div`
  background-color: ${p => p.theme.colour.accent};
  transform: scaleY(1);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-origin: bottom;
`;

const AnimatedFour = styled.div`
  background-color: ${p => p.theme.colour.secondaryDark};
  transform: scaleY(1);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-origin: bottom;
`;

type Props = {
  job: jobLimited;
};

const JobsGrid: FC<Props> = ({ job }) => {
  const { scrollHeight } = useContext(SiteContext);
  const containerRef = useRef(null);
  const overlayRef = useRef(null);
  const [animated, setAnimated] = useState(false);
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 4)
  );

  const animation = () => {
    gsap.to(containerRef.current!, 1, {
      css: {
        clipPath: 'inset(0 0 0% 0)'
      },
      ease: 'power3.inOut'
    });
    setTimeout(() => {
      gsap.to(overlayRef.current!, 1, {
        scaleY: 0,
        ease: 'power3.inOut'
      });
    }, 100);
  };

  useEffect(() => {
    if (!animated) {
      const pos = getObjectCenterPos(containerRef.current!);
      if (pos <= 0) {
        setAnimated(true);
        animation();
      }
    }
  }, [scrollHeight]);

  return (
    <Container>
      <AnimatedContainer ref={containerRef}>
        <ImageContainer>
          <Image image={getImage(job.featureImage as any)!} alt='' />
          <Overlay random={randomNumber} />
        </ImageContainer>
        <Padding>
          <Border>
            <TextContainer>
              <TextBlock>
                <TextSmall>{job.title}</TextSmall>
                <SmallDivider />
                <TextBody>{job.campaign}</TextBody>
                <Divider />
                <TagBox>
                  <Tag>{job.category}</Tag>
                </TagBox>
              </TextBlock>
            </TextContainer>
          </Border>
        </Padding>
        {randomNumber === 0 && <AnimatedOne ref={overlayRef} />}
        {randomNumber === 1 && <AnimatedTwo ref={overlayRef} />}
        {randomNumber === 2 && <AnimatedThree ref={overlayRef} />}
        {randomNumber === 3 && <AnimatedFour ref={overlayRef} />}
      </AnimatedContainer>
    </Container>
  );
};

export default JobsGrid;
