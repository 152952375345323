import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { job } from '../../util/models';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Overlay = styled.div<{ random: number; touching: boolean }>`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background-image: ${p =>
    p.random === 0
      ? `linear-gradient(
    -45deg,
    ${p.theme.colour.secondary},
    ${p.theme.colour.primary}
  )`
      : p.random === 1
      ? `linear-gradient(
    -45deg,
    ${p.theme.colour.secondaryDark},
    ${p.theme.colour.accent}
  )`
      : p.random === 2
      ? `linear-gradient(
    -45deg,
    ${p.theme.colour.primary},
    ${p.theme.colour.primaryDark}
  )`
      : `linear-gradient(
    -45deg,
    ${p.theme.colour.secondaryDark},
    ${p.theme.colour.secondary}
  )`};
  opacity: ${p => (p.touching ? 0.9 : 0)};
  transition: opacity 0.75s ease;
  box-sizing: border-box;
  pointer-events: none;
`;

const Padding = styled.div<{ touching: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  box-sizing: border-box;
  transition: opacity 0.75s ease;
  opacity: ${p => (p.touching ? 1 : 0)};
`;

const Border = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid ${p => p.theme.colour.secondaryLight};
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  box-sizing: border-box;
  padding: 15px;
`;

const TextBlock = styled.div`
  box-sizing: border-box;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  pointer-events: none;
`;

const Image = styled(GatsbyImage)<{ touching: boolean }>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 0.75s ease, filter 0.75s ease;
  transform: ${p => (p.touching ? 'scale(1.2)' : 'scale(1.1)')};
  pointer-events: none;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
`;

const TagBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tag = styled.div`
  padding: 7px;
  border-radius: 5px;
  background-color: ${p => p.theme.colour.secondary};
  color: white;
  font-size: ${p => p.theme.fontSize.body};
  box-sizing: border-box;
  display: inline;
`;

const SmallDivider = styled.div`
  height: 5px;
`;

const Divider = styled.div`
  height: 10px;
`;

const TextSmall = styled.p`
  font-size: ${p => p.theme.fontSize.smallHeading};
  color: white;
  padding: 0;
  margin: 0;
  line-height: 25px;
`;

const TextBody = styled.p`
  font-size: ${p => p.theme.fontSize.body};
  color: white;
  padding: 0;
  margin: 0;
`;

const AnimatedContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

type Props = {
  job: job;
};

const JobsGrid: FC<Props> = ({ job }) => {
  const containerRef = useRef(null);
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 4)
  );

  const [touching, setTouching] = useState(false);

  return (
    <Container
      onTouchStart={() => setTouching(true)}
      onTouchEnd={() => setTouching(false)}
      onTouchCancel={() => setTouching(false)}
    >
      <AnimatedContainer ref={containerRef}>
        <ImageContainer>
          <Image
            touching={touching}
            image={getImage(job.featureImage as any)!}
            alt=''
          />
          <Overlay touching={touching} random={randomNumber} />
        </ImageContainer>
        <Padding touching={touching}>
          <Border>
            <TextContainer>
              <TextBlock>
                <TextSmall>{job.primary}</TextSmall>
                <SmallDivider />
                <TextBody>{job.campaign}</TextBody>
                <Divider />
                <TagBox>
                  <Tag>{job.category}</Tag>
                </TagBox>
              </TextBlock>
            </TextContainer>
          </Border>
        </Padding>
      </AnimatedContainer>
    </Container>
  );
};

export default JobsGrid;
