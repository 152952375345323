import React, { FC, useRef, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Button from '../button/button_browser';
import { Link } from 'gatsby';
import scrollToElement from 'scroll-to-element';
import gsap from 'gsap';
import { getObjectCenterPos } from '../../util';
import { MenuEnum, facilities } from '../../util/models';
import { breakpoint } from '../../util/constant';
import { SiteContext } from '../../context';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Container = styled.div<{ mobile: boolean }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => (p.mobile ? 'auto' : '40px')};
  box-sizing: border-box;
  min-height: ${p => (p.mobile ? '100vh' : 'auto')};
  opacity: 0.99;
  background-image: ${p =>
    `linear-gradient(45deg, ${p.theme.colour.primary}, ${p.theme.colour.secondary})`};
`;

const Video = styled.video<{ isWide: boolean }>`
  width: ${p => (p.isWide ? '100%' : 'auto')};
  height: ${p => (!p.isWide ? '100%' : 'auto')};
  position: absolute;
  z-index: -100;
  filter: saturate(0%);
  opacity: 0.25;
`;

const TextContainer = styled.div<{ mobile: boolean }>`
  top: 0;
  width: 100%;
  box-sizing: border-box;
  grid-area: text;
  font-size: 1em;
  color: ${p => p.theme.colour.white};
  font-weight: 100;
  max-width: 800px;
  text-align: left;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  pointer-events: none;
  user-select: none;
  opacity: ${p => (p.mobile ? 1 : 0)};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Divider = styled.div`
  height: 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  user-select: none;
`;

type Props = {
  facilities: facilities;
};

const Facilities: FC<Props> = ({ facilities }) => {
  const { scrollHeight, deviceSize } = useContext(SiteContext);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [isWide, setIsWide] = useState(false);
  const [animated, setAnimated] = useState(false);

  const animation = () => {
    gsap.to(textRef.current!, 1, {
      opacity: 1,
      ease: 'power3.inOut'
    });
  };

  useEffect(() => {
    if (!animated && deviceSize !== breakpoint.mobile) {
      const pos = getObjectCenterPos(containerRef.current!);
      if (pos <= 0) {
        setAnimated(true);
        animation();
      }
    }
  }, [scrollHeight]);

  useEffect(() => {
    const Resize = () => {
      const container = containerRef.current! as HTMLDivElement;
      setIsWide(container.clientWidth / container.clientHeight >= 1.78);
    };
    Resize();
    window.addEventListener('resize', Resize);
    return () => {
      window.removeEventListener('resize', Resize);
    };
  }, []);

  const linkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    target: string
  ) => {
    if (typeof window !== undefined) {
      if (window.location.pathname === '/') {
        e.preventDefault();
        scrollToElement(target, {
          offset: 0,
          ease: 'outQuad',
          duration: 1000
        });
      }
    }
  };

  const contactClick = () => {};

  return (
    <Container ref={containerRef} mobile={deviceSize === breakpoint.mobile}>
      <React.Fragment>
        <Video
          isWide={isWide}
          src={`${facilities.background}`}
          preload='none'
          autoPlay
          loop
          playsInline
          webkit-playsinline
          muted
          x5-playsinline
        />
        <div>
          <TextContainer
            ref={textRef}
            mobile={deviceSize === breakpoint.mobile}
          >
            {renderRichText(facilities.text)}
          </TextContainer>
          <Divider />
          <ButtonContainer>
            <StyledLink
              to={`/#${MenuEnum.contact}`}
              onClick={e => linkClick(e, `#${MenuEnum.contact}`)}
            >
              <Button
                width={'100px'}
                onClick={contactClick}
                style={'secondary'}
              >
                Contact us
              </Button>
            </StyledLink>
          </ButtonContainer>
        </div>
      </React.Fragment>
    </Container>
  );
};

export default Facilities;
