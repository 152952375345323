import React, { FC, useContext, useEffect } from 'react';
import { SEO } from '../components/seo';
import { MenuEnum, job, person, INewsNode } from '../util/models';
import { breakpoint } from '../util/constant';
import { graphql } from 'gatsby';
import { SiteContext } from '../context';

//sections
import Header_Desktop from '../components/section/desktop/desktop_header';
import Header_Mobile from '../components/section/mobile/mobile_header';
import Home from '../components/section/home';
import Heading from '../components/section/heading';
import Desktop_Projects from '../components/section/desktop/desktop_projects';
import Mobile_Projects from '../components/section/mobile/mobile_projects';
import People from '../components/section/people';
import Facilities from '../components/section/facilities';
import Interactive from '../components/section/interactive';
import Desktop_Awards from '../components/section/desktop/desktop_awards';
import Desktop_Contact from '../components/section/desktop/desktop_contact';
import Mobile_Contact from '../components/section/mobile/mobile_contact';
import NewsSection from '../components/section/newsSection';
import { Clients } from '../components/section/clients';

type Props = { data: any };

const IndexPage: FC<Props> = ({ data }) => {
  const { deviceSize } = useContext(SiteContext);

  const {
    seoDescription,
    seoTitle,
    headerLogo,
    socialLinks,
    showreelUrl,
    sections,
    clientLoginLink,
    landingBackgroundVideo,
    landingDescription,
    projectsAnchor,
    projectsTitle,
    projectsDescription,
    projects,
    newsAnchor,
    news,
    peopleAnchor,
    peopleTitle,
    peopleDescription,
    people,
    studioAnchor,
    studioBackgroundVideo,
    studioDescription,
    partnersAnchor,
    partnersDesktopImage,
    partnersMobileImage,
    awardsAnchor,
    awardsTitle,
    awardsDescription,
    awards,
    contactAnchor,
    contactDescription,
    contactTitle,
    links
  } = data.contentfulHomePage;

  return (
    <>
      <SEO title={seoTitle} description={seoDescription.internal.content} />
      <a id='top' />
      <Header_Mobile
        header={{
          client: clientLoginLink,
          showreel: showreelUrl,
          icons: socialLinks,
          menulabels: sections,
          logo: headerLogo
        }}
        contact={links}
      />
      <Home
        home={{
          background: landingBackgroundVideo,
          text: landingDescription
        }}
      />
      <Header_Desktop
        header={{
          client: clientLoginLink,
          showreel: showreelUrl,
          icons: socialLinks,
          menulabels: sections,
          logo: headerLogo
        }}
      />
      <a
        id={projectsAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      <Heading
        title={projectsTitle}
        description={projectsDescription}
        markdown={true}
      />
      <Desktop_Projects projects={projects} />
      <Mobile_Projects projects={projects} />
      <a
        id={newsAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      {news.length > 0 && <NewsSection news={news} />}
      <a
        id={peopleAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      <Heading
        title={peopleTitle}
        description={peopleDescription}
        markdown={true}
      />
      <People people={people} />
      <a
        id={studioAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      <Facilities
        facilities={{
          background: studioBackgroundVideo,
          text: studioDescription
        }}
      />
      <a
        id={partnersAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      <Clients
        thinImage={partnersMobileImage}
        wideImage={partnersDesktopImage}
      />
      <a
        id={awardsAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      {deviceSize !== breakpoint.mobile && (
        <Heading
          title={awardsTitle}
          description={awardsDescription}
          markdown={true}
        />
      )}
      <Desktop_Awards
        credits={{
          heading: awardsTitle,
          description: awardsDescription,
          credits: awards
        }}
      />
      <a
        id={contactAnchor}
        style={{
          display: 'block',
          position: 'relative',
          top: deviceSize === breakpoint.mobile ? '0px' : '-70px',
          visibility: 'hidden'
        }}
      />
      <Mobile_Contact
        contact={{
          heading: contactTitle,
          description: contactDescription,
          links
        }}
      />
      <Desktop_Contact
        contact={{
          heading: contactTitle,
          description: contactDescription,
          links
        }}
      />
    </>
  );
};

export const query = graphql`
  query IndexQuery {
    contentfulHomePage {
      #settings
      seoDescription {
        internal {
          content
        }
      }
      seoTitle

      # header
      headerLogo {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      socialLinks {
        link
        icon
      }
      showreelUrl
      sections {
        title
        anchor
      }
      clientLoginLink

      # landing
      landingBackgroundVideo
      landingDescription {
        raw
      }

      # projects
      projectsAnchor
      projectsTitle
      projectsDescription {
        raw
      }
      projects {
        category
        title
        campaign
        slug
        tags
        featureImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        updatedAt
      }

      # news
      newsAnchor
      news {
        title
        content {
          raw
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      # people
      peopleAnchor
      peopleTitle
      peopleDescription {
        raw
      }
      people {
        name
        title
        description {
          raw
        }
        images {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      # studio
      studioAnchor
      studioBackgroundVideo
      studioDescription {
        raw
      }

      # partners
      partnersAnchor
      partnersTitle
      partnersMobileImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      partnersDesktopImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }

      # awards
      awardsAnchor
      awardsTitle
      awardsDescription {
        raw
      }
      awards {
        achievement
        category
        title
        type
        link
      }

      # contact
      contactAnchor
      contactDescription {
        raw
      }
      contactTitle
      links {
        title
        linkUrl
        linkText
      }
    }
  }
`;

export default IndexPage;
