import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import Desktop_Person from '../desktop/desktop_person';
import Mobile_Person from '../mobile/mobile_person';
import { breakpoint } from '../../util/constant';
import { person } from '../../util/models';
import { SiteContext } from '../../context';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
`;

const PersonContainer = styled.div`
  margin: 0 50px 50px 50px;
`;

type Props = {
  people: person[];
};

const People: FC<Props> = ({ people }) => {
  const { scrollHeight, deviceSize } = useContext(SiteContext);
  return (
    <Container>
      {people.map((person, index) => (
        <PersonContainer key={`person${index}`}>
          {deviceSize === breakpoint.mobile ? (
            <Mobile_Person person={person} />
          ) : (
            <Desktop_Person person={person} scrollHeight={scrollHeight} />
          )}
        </PersonContainer>
      ))}
    </Container>
  );
};

export default People;
