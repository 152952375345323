import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Button from '../../button/button_browser';
import JobFilter from '../../desktop/desktop_jobFilter';
import Job from '../../desktop/desktop_job';
import SafariJob from '../../desktop/desktop_job_safari';
import { deviceSize } from '../../../util/styledComponents';
import { breakpoint, Sort } from '../../../util/constant';
import { job, jobLimited } from '../../../util/models';
import { getFilteredProjects } from '../../../util';
import { isSafari } from 'react-device-detect';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${p => p.theme.colour.grey1};
  ${deviceSize.lessThan(breakpoint.mobile)`
    display: none;
  `}
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 300px;
`;

const JobContainer = styled.div`
  width: 100%;
  min-height: 300px;
  :first-child {
    grid-column: span 2;
    grid-row: span 2;
  }
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

type Props = {
  projects: jobLimited[];
};

const Desktop_Projects: FC<Props> = ({ projects }) => {
  const [jobLimit, setJobLimit] = useState(9);
  const [filteredProjects, setFilteredProjects] = useState<jobLimited[]>([]);

  const LoadMore = () => {
    if (jobLimit < filteredProjects.length) {
      setJobLimit(jobLimit + 9);
    }
  };

  const onFilter = (search: string, tags: string[], sort: Sort) => {
    setFilteredProjects(getFilteredProjects(projects, search, tags, sort));
  };

  return (
    <Container>
      <JobFilter onFilter={onFilter} />
      <Grid>
        {filteredProjects.slice(0, jobLimit).map((JobObject, index) => (
          <JobContainer key={`${JobObject.title}${index}`}>
            <StyledLink to={`/${JobObject.slug}`} key={`job${index}`}>
              {!isSafari ? (
                <Job job={JobObject} />
              ) : (
                <SafariJob job={JobObject} />
              )}
            </StyledLink>
          </JobContainer>
        ))}
      </Grid>
      {jobLimit < filteredProjects.length && (
        <LoadMoreContainer>
          <Button onClick={LoadMore} width={'100px'}>
            Load More
          </Button>
        </LoadMoreContainer>
      )}
    </Container>
  );
};

export default Desktop_Projects;
