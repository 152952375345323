import { Sort } from './constant';
import { jobLimited } from './models';
import * as Fuse from 'fuse.js';

export const getObjectCenterPos = (element: HTMLElement): number => {
  return element.getBoundingClientRect().top - window.innerHeight + 200;
};

export const getFilteredProjects = (
  allJobs: jobLimited[],
  jobSearch: string,
  jobTags: string[],
  jobSort: Sort
): jobLimited[] => {
  let searchedJobs: jobLimited[] = [];
  if (jobSearch === '') {
    searchedJobs = allJobs;
  } else {
    const fuseOptions: Fuse.FuseOptions<jobLimited> = {
      keys: ['title', 'campaign', 'category'],
      threshold: 0.1
    };
    let fuse = new Fuse.default(allJobs, fuseOptions);
    searchedJobs = fuse.search(jobSearch) as jobLimited[];
  }

  let taggedJobs: jobLimited[] = [];
  if (jobTags.length === 0) {
    taggedJobs = searchedJobs;
  } else {
    for (const job of searchedJobs) {
      for (const tag of jobTags) {
        if (
          job.tags.includes(tag) &&
          taggedJobs.findIndex(x => x === job) === -1
        ) {
          taggedJobs.push(job);
        }
      }
    }
  }

  let sortedJobs: jobLimited[] = [];
  switch (jobSort) {
    case Sort.default:
      sortedJobs = taggedJobs;
      break;
    case Sort.dateDESC:
      sortedJobs = taggedJobs.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
      break;
    case Sort.dateASC:
      sortedJobs = taggedJobs.sort((a, b) => {
        return (
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
      });
      break;
    case Sort.AZ:
      sortedJobs = taggedJobs.sort((a, b) => {
        let jobA = a.title.toUpperCase();
        let jobB = b.title.toUpperCase();
        return jobA < jobB ? -1 : jobA > jobB ? 1 : 0;
      });
      break;
    case Sort.ZA:
      sortedJobs = taggedJobs.sort((a, b) => {
        let jobA = a.title.toUpperCase();
        let jobB = b.title.toUpperCase();
        return jobA > jobB ? -1 : jobA < jobB ? 1 : 0;
      });
      break;
  }

  return sortedJobs;
};
