import React, { FC } from 'react';
import styled from 'styled-components';
import Heading from '../heading';
import ScrollAnimation from 'react-animate-on-scroll';
import { deviceSize } from '../../../util/styledComponents';
import { breakpoint } from '../../../util/constant';
import { contact } from '../../../util/models';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(
    135deg,
    ${p => p.theme.colour.secondaryDark},
    ${p => p.theme.colour.primary}
  );
  min-height: auto;
  ${deviceSize.lessThan(breakpoint.mobile)`
    display: none;
  `}
`;

const Form = styled.form`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;

const TopLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopLine = styled.div`
  display: flex;
  align-items: center;
  max-width: 610px;
  width: 100%;
  justify-content: center;
`;

const SearchField = styled.input`
  flex-grow: 1;
  min-width: 0;
  border: 1px solid ${p => p.theme.colour.white};
  border-radius: 3px;
  background-color: transparent;
  height: 35px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: ${p => p.theme.fontSize.body};
  color: white;
  font-family: 'Poppins';
  box-sizing: border-box;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: white;
    opacity: 0.5;
  }
`;

const AreaContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const TextArea = styled.textarea`
  width: 610px;
  height: 200px;
  border: 1px solid ${p => p.theme.colour.white};
  border-radius: 3px;
  background-color: transparent;
  padding: 5px;
  font-size: ${p => p.theme.fontSize.body};
  color: white;
  font-family: 'Poppins';
  box-sizing: border-box;
  resize: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: white;
    opacity: 0.5;
  }
`;

const Divider = styled.div`
  height: 50px;
`;

const SmallDivider = styled.div`
  height: 10px;
`;

const HorizontalDivider = styled.div`
  width: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  width: 610px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
`;

const ContactText = styled.p`
  font-size: ${p => p.theme.fontSize.body};
  color: white;
  padding: 5px 10px 5px 10px;
  margin: 0;
  text-align: center;
`;

const MapText = styled.a`
  font-size: ${p => p.theme.fontSize.body};
  color: white;
  padding: 5px 10px 5px 10px;
  margin: 0;
  text-align: center;
  cursor: pointer;
  :hover {
    color: ${p => p.theme.colour.secondaryLight};
  }
  text-decoration: none;
`;

const YellowText = styled.span`
  color: ${p => p.theme.colour.secondary};
  user-select: none;
  pointer-events: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 610px;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
`;

const Button = styled.button`
  background-color: ${p => p.theme.colour.secondary};
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 70px;
  border: none;
  height: 30px;
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colour.secondaryLight};
  }
  :active {
    background-color: ${p => p.theme.colour.primary};
  }
  outline: none;
`;

type Props = { contact: contact };

const Desktop_Contact: FC<Props> = ({ contact }) => {
  return (
    <Container>
      {contact && (
        <ScrollAnimation animateIn={'fadeIn'} animateOnce={true}>
          <Heading
            title={contact.heading}
            description={contact.description}
            allWhite={true}
            markdown={true}
          />
        </ScrollAnimation>
      )}
      <ScrollAnimation animateIn={'slideInLeft'} animateOnce={true}>
        <Form
          name='Contact Form'
          method='POST'
          data-netlify='true'
          action='/thanks'
        >
          <input type='hidden' name='form-name' value='Contact Form' />
          <TopLineWrapper>
            <TopLine>
              <SearchField
                placeholder={'email address'}
                type={'email'}
                required={true}
                name={'email'}
              />
              <HorizontalDivider />
              <SearchField
                placeholder={'name'}
                type={'text'}
                required={true}
                name={'name'}
              />
            </TopLine>
          </TopLineWrapper>
          <SmallDivider />
          <AreaContainer>
            <TextArea
              placeholder={'write message...'}
              required={true}
              name={'message'}
            />
          </AreaContainer>
          <Wrapper>
            <ButtonContainer>
              <Button type={'submit'}>Send</Button>
            </ButtonContainer>
          </Wrapper>
        </Form>
      </ScrollAnimation>
      <Divider />
      <ScrollAnimation animateIn={'fadeIn'} animateOnce={true} offset={-200}>
        <Wrapper>
          {contact && (
            <TextContainer>
              {contact.links.map(link => (
                <MapText href={link.linkUrl} target='_blank'>
                  <YellowText>{`${link.title} `}</YellowText>
                  {link.linkText}
                </MapText>
              ))}
            </TextContainer>
          )}
        </Wrapper>
      </ScrollAnimation>
      <Divider />
    </Container>
  );
};

export default Desktop_Contact;
