import React, { FC, useRef, useState, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import lottie, { AnimationItem } from 'lottie-web';
import { getObjectCenterPos } from '../../util';
import { award } from '../../util/models';

const LaurelAnim = require('../../images/JSON/laurel.json');
const LaurelPulse = require('../../images/JSON/laurel-pulse.json');

const SVGContainer = styled.svg`
  height: 100%;
  width: auto;
`;

const SVGContainer2 = styled.svg`
  height: 100%;
  width: auto;
  transform: scaleX(-1);
`;

const TextAnim = keyframes`
  from {
    opacity: 0;
  }
  to { opacity: 1; }
`;

const TextContainer = styled.div`
  width: 150px;
  padding: 5px;
  animation: ${TextAnim} 0.5s ease-in;
`;

const Screening = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: ${p => p.theme.colour.dark};
  text-align: center;
  user-select: none;
`;

const Award = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: ${p => p.theme.colour.dark};
  text-align: center;
  user-select: none;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
`;

const Line = styled.div`
  height: 1px;
  width: 40%;
  background-color: ${p => p.theme.colour.primary};
`;

const Result = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 900;
  color: ${p => p.theme.colour.primary};
  text-align: center;
  user-select: none;
`;

const Container = styled.div<{ hasLink: boolean }>`
  height: 120px;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${p => (p.hasLink ? 'pointer' : 'default')};
  :hover ${Screening} {
    color: ${p => p.theme.colour.primary};
  }
  :hover ${Award} {
    color: ${p => p.theme.colour.primary};
  }
  :hover ${Line} {
    background-color: ${p => p.theme.colour.primary};
  }
  :hover ${Result} {
    color: ${p => p.theme.colour.primary};
  }
`;

type Props = {
  onClick(): void;
  hasLink: boolean;
  award: award;
  scrollHeight: number;
};

const Laurel: FC<Props> = ({ award, onClick, hasLink, scrollHeight }) => {
  const Laurel1 = useRef(null);
  const Laurel2 = useRef(null);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [laurel1Anim, setLaurel1Anim] = useState<AnimationItem | undefined>(
    undefined
  );
  const [laurel2Anim, setLaurel2Anim] = useState<AnimationItem | undefined>(
    undefined
  );

  const [animated, setAnimated] = useState(false);

  const [active, setActive] = useState(false);

  const TriggerPulse = () => {
    if (laurel1Anim) {
      laurel1Anim.destroy();
    }
    if (laurel2Anim) {
      laurel2Anim.destroy();
    }
    setLaurel1Anim(
      lottie.loadAnimation({
        container: Laurel1.current!,
        animationData: LaurelPulse,
        loop: false,
        autoplay: true,
        renderer: 'svg'
      })
    );
    setLaurel2Anim(
      lottie.loadAnimation({
        container: Laurel2.current!,
        animationData: LaurelPulse,
        loop: false,
        autoplay: true,
        renderer: 'svg'
      })
    );
  };

  useEffect(() => {
    if (!animated) {
      const pos = getObjectCenterPos(containerRef.current!);
      if (pos <= 0) {
        setActive(true);
        setAnimated(true);
        setLaurel1Anim(
          lottie.loadAnimation({
            container: Laurel1.current!,
            animationData: LaurelAnim,
            loop: false,
            autoplay: true,
            renderer: 'svg'
          })
        );
        setLaurel2Anim(
          lottie.loadAnimation({
            container: Laurel2.current!,
            animationData: LaurelAnim,
            loop: false,
            autoplay: true,
            renderer: 'svg'
          })
        );
      }
    }
  }, [scrollHeight]);

  const Hover = () => {
    TriggerPulse();
  };

  return (
    <Container
      onMouseEnter={Hover}
      onClick={onClick}
      ref={containerRef}
      hasLink={hasLink}
    >
      <SVGContainer viewBox={'0 0 200 350'}>
        <g ref={Laurel1} />
      </SVGContainer>
      {active && (
        <TextContainer ref={textRef}>
          <Screening>{award.title}</Screening>
          <Award>{award.category}</Award>
          <LineContainer>
            <Line />
          </LineContainer>
          <Result>{award.achievement}</Result>
        </TextContainer>
      )}
      <SVGContainer2 viewBox={'0 0 200 350'}>
        <g ref={Laurel2} />
      </SVGContainer2>
    </Container>
  );
};

export default Laurel;
