import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Button from '../../button/button_browser';
import Job from '../../mobile/mobile_job';
import { deviceSize } from '../../../util/styledComponents';
import { breakpoint } from '../../../util/constant';
import { job } from '../../../util/models';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${p => p.theme.colour.grey1};
  ${deviceSize.greaterThan(breakpoint.mobile)`
    display: none;
  `}
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 300px;
`;

const JobContainer = styled.div`
  width: 100%;
  min-height: 300px;
  :first-child {
    grid-column: span 1;
    grid-row: span 1;
  }
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

type Props = {
  projects: job[];
};

const Mobile_Projects: FC<Props> = ({ projects }) => {
  const [jobLimit, setJobLimit] = useState(9);

  const LoadMore = () => {
    if (jobLimit < projects.length) {
      setJobLimit(jobLimit + 9);
    }
  };

  return (
    <Container>
      <Grid>
        {projects.slice(0, jobLimit).map((JobObject, index) => (
          <JobContainer key={`${JobObject.primary}${index}`}>
            <StyledLink to={`/${JobObject.slug}`} key={`job${index}`}>
              <Job job={JobObject} />
            </StyledLink>
          </JobContainer>
        ))}
      </Grid>
      {jobLimit < projects.length && (
        <LoadMoreContainer>
          <Button onClick={LoadMore} width={'100px'}>
            Load More
          </Button>
        </LoadMoreContainer>
      )}
    </Container>
  );
};

export default Mobile_Projects;
