import { Share } from './constant';

export interface image {
  gatsbyImageData: any;
}

export interface icon {
  icon: string;
  link: string;
}

export interface header {
  client: string;
  showreel: string;
  icons: icon[];
  menulabels: {
    title: string;
    anchor: string;
  }[];
  logo: image;
}

export interface contact {
  description: any;
  heading: string;
  links: {
    title: string;
    linkUrl: string;
    linkText: string;
  }[];
}

export interface award {
  title: string;
  category: string;
  achievement: string;
  type: string;
  link: string | null | undefined;
}

export interface credits {
  description: string;
  heading: string;
  credits: award[];
}

export interface facilities {
  background: string;
  text: any;
}

export interface clients {
  thinImage: {
    sourceUrl: string;
  };
  wideImage: {
    sourceUrl: string;
  };
}

export interface home {
  background: string;
  text: any;
}

export interface interactive {
  link: string;
}

export interface seo {
  title: string;
  description: string;
}

export interface people {
  description: string;
  heading: string;
}

export interface work {
  description: string;
  heading: string;
}

export interface content {
  contact: contact;
  header: header;
  credits: credits;
  facilities: facilities;
  clients: clients;
  home: home;
  interactive: interactive;
  people: people;
  work: work;
  seo: seo;
}

export interface person {
  images: image[];
  name: string;
  title: string;
  description: any;
}

export interface tag {
  name: string;
  id: string;
}

export interface video {
  link: string;
}

export interface job {
  order: number;
  category: string;
  date: string;
  primary: string;
  campaign: string;
  slug: string;
  text: string;
  tags: string[];
  video: video[];
  featureImage: image;
  images: image[];
  updatedAt: string;
}

export interface jobLimited {
  category: string;
  title: string;
  campaign: string;
  slug: string;
  tags: string[];
  featureImage: image;
  updatedAt: string;
}

export interface project {
  category: string;
  date: string;
  primary: string;
  campaign: string;
  slug: string;
  description: string;
  tags: tag[];
  share: Share[];
  featureimage: image;
  content: column[];
}

export interface column {
  columns: string;
  column1: content_base[];
  column2: content_base[];
}

export interface content_base {
  __typename: string;
}
export interface content_title extends content_base {
  heading: string;
  subheading: string | undefined;
  text: string | undefined;
}
export interface button {
  title: string;
  link: string;
}
export interface content_paragraph extends content_base {
  heading: string | undefined;
  text: string;
  buttons: button[] | undefined;
}
export interface content_quote extends content_base {
  quote: string;
  author?: string;
}
export interface content_image extends content_base {
  cropped: boolean | undefined;
  image: image;
}
export interface content_stackedImages extends content_base {
  images: image[];
}
export interface content_video extends content_base {
  link: string;
}
export interface credit {
  title: string;
  name: string;
  link: string | undefined;
}
export interface content_credits extends content_base {
  stacked: boolean | undefined;
  credits: credit[];
}
export interface link {
  url: string;
  icon: string;
  title: string | undefined;
}
export interface content_links extends content_base {
  stacked: boolean;
  links: link[];
}
export interface content_gallery extends content_base {
  square: boolean | undefined;
  images: image[];
}
export interface content_iframe extends content_base {
  link: string;
}

export type MenuHeight = {
  home: number;
  work: number;
  people: number;
  facilities: number;
  awards: string;
  contact: number;
};

export type MenuName = {
  home: string;
  work: string;
  people: string;
  facilities: string;
  awards: string;
  contact: string;
};

export enum MenuEnum {
  home = 'home',
  work = 'work',
  people = 'people',
  facilities = 'facilities',
  awards = 'awards',
  contact = 'contact',
  news = 'news'
}

export interface INewsNode {
  title: string;
  content: any;
  image: image;
}

export interface contentfulCredits {
  credits: {
    title: string;
    name: string;
    link?: string;
  }[];
  stacked: boolean;
}
export interface contentfulImage {
  image: { file: { url: string } };
  cropped: boolean;
}
export interface contentfulParagraph {
  text: {
    text: string;
  };
  heading: string;
  buttons: {
    link: string;
    title: string;
  }[];
  center: boolean;
}
export interface contentfulGallery {
  images: { file: { url: string } }[];
  square: boolean;
}
export interface contentfulIframe {
  link: string;
}
export interface contentfulImages {
  images: { file: { url: string } }[];
}
export interface contentfulQuote {
  quote: { quote: string };
  author: string;
  center: boolean;
}
export interface contentfulTitle {
  heading: string;
  subHeading: string;
  text: { text: string };
  center: boolean;
}
export interface contentfulVideo {
  link: string;
  fullWidth: boolean;
}
export interface layout {
  elements: any[];
}
