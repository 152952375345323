import React, { FC } from 'react';
import styled from 'styled-components';
import AnimatedLogo from '../animatedLogo';
import { deviceSize } from '../../util/styledComponents';
import { breakpoint } from '../../util/constant';
import { home } from '../../util/models';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 50px;
  display: grid;
  ${deviceSize.lessThan(breakpoint.mobile)`
    transform: translateY(-100px);
  `}
  grid-template-rows: 1fr 200px 1fr;
  grid-gap: 50px;
  grid-template-areas:
    '.'
    'logo'
    'text';
`;

const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  grid-area: logo;
  > div {
    width: 100%;
    height: 100%;
    max-width: 500px;
  }
`;

const TextContainer = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  grid-area: text;
`;

const Markdown = styled.div`
  > p {
    font-size: 1em;
    color: white;
    font-weight: 100;
    max-width: 600px;
    text-align: center;
    padding: 0;
    margin: 0;
    pointer-events: none;
    user-select: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

const Video = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
`;

type Props = {
  home: home;
};

const Home: FC<Props> = ({ home }) => {
  return (
    <Container>
      <Video
        src={home.background}
        preload='none'
        autoPlay
        loop
        playsInline
        webkit-playsinline
        muted
        x5-playsinline
      />
      <Overlay />
      <Content>
        <Logo>
          <div>
            <AnimatedLogo pulseDelay={7000} />
          </div>
        </Logo>
        <TextContainer>
          <Markdown>{renderRichText(home.text)}</Markdown>
        </TextContainer>
      </Content>
    </Container>
  );
};

export default Home;
