import React, { FC, FormEvent, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DropdownMenu from '../dropdownMenu';
import Icon from '../button/icon';
import {
  Sort,
  breakpoint,
  ProjectTagListPartial,
  ProjectTagList
} from '../../util/constant';
import { SiteContext } from '../../context';

const Container = styled.div`
  width: 100%;
`;

const SearchBar = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${p => p.theme.colour.grey1};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 5px;
`;

const TabsBar = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-image: linear-gradient(
    90deg,
    ${p => `${p.theme.colour.primary}, ${p.theme.colour.primaryDark}`}
  );
`;

const Tags = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 5px;
`;

const SearchField = styled.input`
  flex-grow: 1;
  background-color: transparent;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  font-size: ${p => p.theme.fontSize.body};
  color: ${p => p.theme.colour.grey5};
  font-family: 'Poppins';
  box-sizing: border-box;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${p => p.theme.colour.grey3};
  }
`;

const Line = styled.div`
  height: 60%;
  width: 1px;
  background-color: ${p => p.theme.colour.grey3};
  margin-left: 3px;
`;

const Tag = styled.div<{ active: boolean }>`
  flex-grow: 1;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  border-radius: 3px;
  background-color: ${p => (p.active ? p.theme.colour.accent : 'transparent')};
  color: ${p => p.theme.colour.white};
  :hover {
    background-color: ${p =>
      p.active ? p.theme.colour.secondary : p.theme.colour.primaryLight};
  }
  cursor: pointer;
`;

const Text = styled.p`
  user-select: none;
  font-size: 14px;
  text-align: center;
`;

const Divider = styled.div`
  height: 60%;
  width: 1px;
  background-color: ${p => p.theme.colour.white};
  opacity: 0.6;
  margin-left: 3px;
  margin-right: 3px;
`;

type Props = {
  onFilter(search: string, tags: string[], sort: Sort): void;
};

const Desktop_Filter: FC<Props> = ({ onFilter }) => {
  const { deviceSize } = useContext(SiteContext);
  const [jobSort, setJobSort] = useState(Sort.default);
  const [jobTags, setJobTags] = useState<string[]>([]);
  const [jobSearch, setJobSearch] = useState('');

  const [filmActive, setFilmActive] = useState(false);
  const [commericalActive, setCommericalActive] = useState(false);
  const [corporateActive, setCorporateActive] = useState(false);
  const [socialActive, setSocialActive] = useState(false);
  const [podcastActive, setPodcastActive] = useState(false);
  const [currentTags, setTags] = useState<string[]>([]);

  useEffect(() => {
    let newTags = [...currentTags];

    if (filmActive) newTags.push('film');
    if (commericalActive) newTags.push('commercial');
    if (corporateActive) newTags.push('corporate');
    if (socialActive) newTags.push('social');
    if (podcastActive) newTags.push('podcast');

    setJobTags(newTags);
  }, [
    currentTags,
    filmActive,
    commericalActive,
    corporateActive,
    socialActive,
    podcastActive
  ]);

  const onSort = (item: string[]) => {
    const newItem: Sort = item[0] as Sort;
    setJobSort(newItem);
  };

  const setSearch = (value: FormEvent<HTMLInputElement>) => {
    const search = value.target as HTMLInputElement;
    setJobSearch(search.value);
  };

  const resetTags = () => {
    setFilmActive(false);
    setCommericalActive(false);
    setCorporateActive(false);
    setPodcastActive(false);
    setSocialActive(false);
    setTags([]);
  };

  useEffect(() => {
    onFilter(jobSearch, jobTags, jobSort);
  }, [jobSearch, jobTags, jobSort]);

  return (
    <Container>
      {deviceSize === breakpoint.desktop ? (
        <>
          <SearchBar>
            <SearchField
              placeholder={'search work...'}
              value={jobSearch}
              onChange={setSearch}
            />
            <Line />
            <DropdownMenu
              menuItems={[
                Sort.default,
                Sort.dateDESC,
                Sort.dateASC,
                Sort.AZ,
                Sort.ZA
              ]}
              onSelectActive={onSort}
              width={120}
              offsetHeight={'10px'}
              closeOnClick={true}
              zindex={6}
            />
          </SearchBar>
          <TabsBar>
            <Tags>
              <Tag
                active={filmActive}
                onClick={() => setFilmActive(!filmActive)}
              >
                <Text>FILM</Text>
              </Tag>
              <Divider />
              <Tag
                active={commericalActive}
                onClick={() => setCommericalActive(!commericalActive)}
              >
                <Text>COMMERCIAL</Text>
              </Tag>
              <Divider />
              <Tag
                active={corporateActive}
                onClick={() => setCorporateActive(!corporateActive)}
              >
                <Text>CORPORATE</Text>
              </Tag>
              <Divider />
              <Tag
                active={socialActive}
                onClick={() => setSocialActive(!socialActive)}
              >
                <Text>SOCIAL</Text>
              </Tag>
              <Divider />
              <Tag
                active={podcastActive}
                onClick={() => setPodcastActive(!podcastActive)}
              >
                <Text>PODCAST</Text>
              </Tag>
            </Tags>
            <Divider />
            <Icon type={'cross'} onClick={resetTags} />
            <Divider />
            <DropdownMenu
              menuItems={ProjectTagListPartial}
              onSelectActive={setTags}
              selectMultiple={true}
              heading={'More Tags'}
              width={120}
              offsetHeight={'10px'}
              zindex={5}
              altStyle={true}
            />
          </TabsBar>
        </>
      ) : (
        <SearchBar>
          <SearchField
            placeholder={'search work...'}
            value={jobSearch}
            onChange={setSearch}
          />
          <Line />
          <DropdownMenu
            menuItems={[
              Sort.default,
              Sort.dateDESC,
              Sort.dateASC,
              Sort.AZ,
              Sort.ZA
            ]}
            onSelectActive={onSort}
            width={120}
            offsetHeight={'10px'}
            closeOnClick={true}
            zindex={6}
          />
          <Line />
          <DropdownMenu
            menuItems={ProjectTagList}
            onSelectActive={setTags}
            selectMultiple={true}
            heading={'More Tags'}
            width={120}
            offsetHeight={'10px'}
            zindex={5}
          />
          <Line />
          <Icon type={'cross'} onClick={resetTags} style={'grey'} />
        </SearchBar>
      )}
    </Container>
  );
};

export default Desktop_Filter;
